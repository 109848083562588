<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >

    <div v-if="wallets !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perState"
                  :options="perStateOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50 w-50"
              />
              <label>وضعیت</label>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="جستجو..."
                />
              </div>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-md-0 mt-2"
            >
              <b-button
                  variant="success"
                  class=""
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="downloadFile"
              >
                دانلود اکسل لیست
              </b-button>
            </b-col>
          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="wallets"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="`https://api.maxterms.com/${data.item.userInfo.selfieFileData}`"
                    :variant="`success`"
                    :to="{ name: 'apps-users-edit', params: { id: data.item.userInfo.userId } }"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-users-edit', params: { id: data.item.userInfo.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.userInfo.name+' '+data.item.userInfo.familyName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userId }}</small>
            </b-media>
          </template>

          <!-- Column: walletId -->
          <template #cell(walletId)="data">
            <b-badge
                pill
                class="text-capitalize"
                :variant="``"
            >
              {{ data.item.walletId }}
            </b-badge>
          </template>

          <!-- Column: credit -->
          <template #cell(credit)="data">
            <span>
               {{ makePrettyPrice(data.item.credit) }}
            </span>
          </template>

          <!-- Column: updateDate -->
          <template #cell(updateDate)="data">
            <span>
              {{data.item.updateDate.slice(11,16)}}
              -
              {{createJalaliDate(data.item.updateDate.slice(0,10))}}
            </span>
          </template>

          <!-- Column: state -->
          <template #cell(userInfo.state)="data">
            <b-badge
                v-if="data.item.userInfo.state === 1"
                pill
                class="text-capitalize"
                :variant="`success`"
            >
              وارد شده
            </b-badge>
            <b-badge
                v-else-if="data.item.userInfo.state === 2"
                pill
                class="text-capitalize"
                :variant="`warning`"
            >
              فالو نکرده
            </b-badge>
            <b-badge
                v-else-if="data.item.userInfo.state === 3"
                pill
                class="text-capitalize"
                :variant="`danger`"
            >
              آیدی اشتباه
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">


                <b-link :to="{ name: 'apps-financial-wallets-info', params: { id: data.item.userId } }">
                  <feather-icon icon="EditIcon" size="20" />
                </b-link>
              </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {WalletGetAllRequest} from "@/libs/Api/Wallet";
import Helper from "@/libs/Helper";
import {getUserData} from "@/auth/utils";
import axiosIns from "@/libs/axios";
import {saveAs} from 'file-saver';


export default {
  name: "WalletsList",
  title:"لیست موجودی ها - پنل ادمین مکس ",
  data(){
    return{
      wallets:null,
      currentPage:1,
      totalCounts:null,
      perPage:10,
      overlay:false,
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'name' ,label :'اطلاعات کاربر'},
        { key: 'userInfo.nationalId' ,label :'کد ملی'},
        { key: 'userInfo.mobile' ,label :'موبایل'},
        { key: 'userInfo.state' ,label :'وضعیت کاربر'},
        { key: 'walletId' ,label :'شناسه کیف پول'},
        { key: 'credit' ,label :'موجودی(تومان)'},
        { key: 'updateDate' ,label :'آخرین تغییر'},
        { key: 'actions',label:'ویرایش کیف پول'},
      ],
      searchQuery:'',
      perState:'',
      perStateOptions:[
        {label:'همه',value:''},
        {label:'وارد شده',value:1},
        {label:'فالو نکرده',value:2},
        {label:'آیدی اشتباه',value:3}
      ],
    }
  },
  async created() {
    await this.getWallets(this.perPage,this.currentPage,this.searchQuery,this.perState)
  },
  methods:{
    refreshData(){
      this.getWallets(this.perPage,this.currentPage,this.searchQuery)
    },
    async getWallets(count,pageNumber,search,state){
      let _this = this;
      _this.overlay = true;
      let data = {
        pageNumber:pageNumber,
        count:count,
        searchCommand:search,
        state:state
      }

      let walletGetAllRequest = new WalletGetAllRequest(_this);
      walletGetAllRequest.setParams(data);
      await walletGetAllRequest.fetch(function (content){
        _this.overlay = false;
        _this.wallets = content.wallets;
        _this.totalCounts = content.walletsCount;
      },function (error){
        _this.overlay = false;
        console.log(error);
      })
    },
    async downloadFile() {
      let _this = this;
      _this.overlay = true;
      let userToken = getUserData().data.token;

      await axiosIns.post(`Wallet/GetExcel`, null, {
        responseType: 'blob',
        'Authorization': userToken,
      }).then(resp => {
        _this.overlay = false;
        saveAs(resp.data, 'لیست موجودی ها.xlsx');
      })
          .catch((error) => {
            _this.overlay = false;
            console.log(error);
          });
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
  },
  watch:{
    perPage:function (nv,ov){
      this.getWallets(nv,this.currentPage,this.searchQuery,this.perState);
    },
    currentPage:function (nv,ov) {
      this.getWallets(this.perPage,nv,this.searchQuery,this.perState);
    },
    searchQuery:function (nv,ov){
      if (nv.length > 3){
        this.getWallets(this.perPage,this.currentPage,nv,this.perState)
      }
    },
    perState:function (nv,ov){
      this.getWallets(this.perPage,this.currentPage,this.searchQuery,nv)
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
